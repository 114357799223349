// src/App.js
import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './Home';
import Leaderboard from './Leaderboard';
import Preloader from './Preloader'; // Import the Preloader
import Navbar from './Navbar'; // Import the Navbar
import './App.css';

function App() {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before fetching
      try {
        const response = await fetch('http://localhost:5000/api/leaderboard', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        setError(error.message);
        console.error('There was a problem with the fetch operation:', error);
      } finally {
        // Wait for a brief moment before setting loading to false
        setTimeout(() => {
          setLoading(false); // Set loading to false after fetching and waiting
        }, 500); // Adjust this duration as needed
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      {loading && <Preloader />} {/* Show preloader while loading */}
      <Navbar setLoading={setLoading} /> {/* Pass setLoading to Navbar */}
      <Routes>
        <Route path="/" element={<Home setLoading={setLoading} />} />
        <Route path="/leaderboard" element={<Leaderboard setLoading={setLoading} />} />
      </Routes>
    </div>
  );
}

export default App;