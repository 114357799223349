// src/Home.js
import React, { useEffect } from 'react';
import Navbar from './Navbar'; // Ensure you have this component
import Footer from './Footer'; // Ensure you have this component
import rustmagicLogo from './images/rustmagic.png';
import rustcoin from './images/rustcoin.svg';
import chickenLogo from './images/chicken.png';
import chickenCoin from './images/chicken-coin.png';
import hypedropLogo from './images/hypedrop.png';
import kickIcon from './images/kick-icon1.png';
import discordIcon from './images/discord-icon.png';
import youtubeIcon from './images/youtube-icon.png';
import './styles.css'; // Import your styles

const Home = ({ setLoading }) => { // Accept setLoading as a prop
    useEffect(() => {
        const particlesContainer = document.getElementById('particles-js');

        for (let i = 0; i < 50; i++) {  // Adjust the number of particles as needed
            const particle = document.createElement('div');
            particle.className = 'floating-particle';

            // Randomize the starting position of each particle
            particle.style.left = `${Math.random() * 100}vw`;
            // Randomize the vertical position to stay within the visible viewport
            particle.style.top = `${Math.random() * (window.innerHeight - 20)}px`; // 20px is the height of the particle

            // Randomize the duration and delay of each particle's animation
            particle.style.animationDuration = `${10 + Math.random() * 20}s`;
            particle.style.animationDelay = `${Math.random() * 5}s`;

            particlesContainer.appendChild(particle);
        }
    }, []);

    return (
        <>
            <div id="particles-js"></div>


            <main>
                {/* Leaderboard Section */}
                <section className="leaderboard-overview">
                    <h2>My Leaderboard!</h2>
                    <div className="leaderboard-score">
                        {/* First Logo and Coin on the Left with overlay */}
                        <div className="left-score coming-soon">
                            <img src={rustmagicLogo} className="big-chicken-logo" alt="Rustmagic Logo" />
                            <div className="overlay-text">Coming Soon</div>
                            <div className="score-wrapper">
                                <img src={rustcoin} className="coin-icon" alt="Rust Coin Icon" />
                                <span className="score-text">2,000</span>
                            </div>
                        </div>

                        {/* Vertical Divider Left */}
                        <div className="divider-left"></div>

                        {/* Second Logo and Coin in the Middle */}
                        <div className="middle-score">
                            <a href="leaderboard" className="score-link">
                                <img src={chickenLogo} className="big-chicken-logo" alt="Big Chicken Logo" />
                            </a>
                            <div className="score-wrapper">
                                <a href="leaderboard" className="score-link">
                                    <img src={chickenCoin} className="coin-icon" alt="Chicken Coin Icon" />
                                    <span className="score-text">500</span>
                                </a>
                            </div>
                        </div>

                        {/* Vertical Divider Middle */}
                        <div className="divider-middle"></div>

                        {/* Third Logo and Coin on the Right with overlay */}
                        <div className="right-score coming-soon">
                            <img src={hypedropLogo} className="big-chicken-logo" alt="Hypedrop Logo" />
                            <div className="overlay-text">Coming Soon</div>
                            <div className="score-wrapper">
                                <span className="score-text">$3,000</span>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Bonuses Section */}
                <section className="game-cards">
                    <h2>Sign-up under my codes here!</h2>
                    <div className="cards-container">
                        <div className="card">
                            <img src={chickenLogo} alt="Game 1" className="card-image" />
                            <div className="card-content">
                                <p className="gradient-text">Code : V3NLI</p>
                                <a href="https://chicken.gg/r/v3nli" className="play-button" target="_blank" rel="noopener noreferrer">Play Now</a>
                            </div>
                        </div>
                        <div className="card">
                            <img src={hypedropLogo} alt="Game 2" />
                            <div className="card-content">
                                <p className="gradient-text">Code : V3NLI</p>
                                <a href="https://www.hypedrop.com/r/V3NLI" className="play-button" target="_blank" rel="noopener noreferrer">Play Now</a>
                            </div>
                        </div>
                        <div className="card">
                            <img src={rustmagicLogo} alt="Game 3" />
                            <div className="card-content">
                                <p className="gradient-text">Code : V3NLI</p>
                                <a href="https://rustmagic.com/r/v3nli" className="play-button" target="_blank" rel="noopener noreferrer">Play Now</a>
                            </div>
                        </div>
                    </div>
                </section>

                {/* YouTube Videos Section */}
                <section className="youtube-videos">
                    <h2 className='ytalign-left'>Watch My Videos!</h2>
                    <div className="video-container">
                        <iframe
                            src="https://www.youtube.com/embed/lVklVvm6A5g"
                            title="YouTube Video 1"
                            frameBorder="0"
                            allowFullScreen
                            className="video-embed"
                        />
                        <iframe
                            src="https://www.youtube.com/embed/pRPCEIBTbxk"
                            title="YouTube Video 2"
                            frameBorder="0"
                            allowFullScreen
                            className="video-embed"
                        />
                        <iframe
                            src="https://www.youtube.com/embed/QrlL3-Tyybc"
                            title="YouTube Video 3"
                            frameBorder="0"
                            allowFullScreen
                            className="video-embed"
                        />
                    </div>
                </section>

                {/* Social Media Cards Section */}
                <section className="social-media-cards">
                    <h2>Follow Me</h2>
                    <div className="cards-container">
                        <div className="card">
                            <img src={kickIcon} alt="Kick" />
                            <div className="card-content">
                                <h3>Kick</h3>
                                <p>Follow me on Kick</p>
                                <a href="https://kick.com/v3nli" target="_blank" rel="noopener noreferrer" className="play-button">Follow Now</a>
                            </div>
                        </div>
                        <div className="card">
                            <img src={discordIcon} alt="Discord" />
                            <div className="card-content">
                                <h3>Discord</h3>
                                <p>Join my Discord server</p>
                                <a href="https://discord.com/invite/5vEcPew9qF" target="_blank" rel="noopener noreferrer" className="play-button">Join Now</a>
                            </div>
                        </div>
                        <div className="card">
                            <img src={youtubeIcon} alt="YouTube" />
                            <div className="card-content">
                                <h3>YouTube</h3>
                                <p>Subscribe to my channel</p>
                                <a href="https://www.youtube.com/channel/UCHJDAO3V9tYOJLP1VduPPGg?sub_confirmation=1" target="_blank" rel="noopener noreferrer" className="play-button">Subscribe</a>
                            </div>
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </>
    );
};

export default Home;
