// src/Preloader.js
import React, { useEffect } from 'react';
import './Preloader.css';
import logo from './images/favise.png'; // Update with the correct path to your logo

const Preloader = () => {
    useEffect(() => {
        const createBubbles = () => {
            const bubbleCount = 30; // Adjust the number of bubbles
            const preloader = document.querySelector('.preloader');
            
            for (let i = 0; i < bubbleCount; i++) {
                const bubble = document.createElement('div');
                bubble.className = 'bubble';
                
                // Set random size and position
                const size = Math.random() * 20 + 10; // Between 10px and 30px
                bubble.style.width = `${size}px`;
                bubble.style.height = `${size}px`;
                bubble.style.left = `${Math.random() * 100}vw`;
                bubble.style.top = `${Math.random() * 100}vh`;

                // Set random animation duration
                bubble.style.animationDuration = `${Math.random() * 2 + 2}s`; // Between 2s and 4s
                
                // Append bubble to preloader
                preloader.appendChild(bubble);
            }
        };

        createBubbles();
    }, []);

    return (
        <div className="preloader">
            <img src={logo} alt="Loading..." className="logoo" />
        </div>
    );
};

export default Preloader;
