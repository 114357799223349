// src/index.js
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom'; // Import Router
import App from './App'; // Import App component
import './index.css';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
    <React.StrictMode>
        <Router>
            <App /> {/* Render the App component with routing */}
        </Router>
    </React.StrictMode>,
    document.getElementById('root')
);

reportWebVitals(); // Call reportWebVitals