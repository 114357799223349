import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './styles.css';
import logoIcon from './images/favise.png';
import AdminModal from './AdminModal';

const Navbar = ({ setLoading }) => {
    const [isAdminModalOpen, setAdminModalOpen] = useState(false);

    const handleNavigation = (path) => {
        setLoading(true);
        window.location.href = path;
    };

    const handleAdminLogin = () => {
        setAdminModalOpen(true); // Open admin modal on click
    };

    return (
        <header>
            <nav className="navbar">
                <div className="container">
                    <Link to="/" className="logo">
                        <img src={logoIcon} alt="Logo Icon" className="logo-icon" />
                        V3nli's Reward Site
                    </Link>
                    <ul className="nav-links">
                        <li>
                            <span className="nav-item" onClick={() => handleNavigation('/')}>Home</span>
                        </li>
                        <li>
                            <span className="nav-item" onClick={() => handleNavigation('/leaderboard')}>Leaderboard</span>
                        </li>
                        <li>
                            <span className="nav-item" onClick={handleAdminLogin}>Admin Login</span>
                        </li>
                    </ul>
                </div>
            </nav>
            <AdminModal 
                isOpen={isAdminModalOpen} 
                onClose={() => setAdminModalOpen(false)} 
                onLogin={(key) => {
                    if (key === "v3your8j2ls9e") {
                        // Call the function to make Send button visible or perform any other admin actions
                        alert("Admin access granted.");
                    } else {
                        alert("Invalid Secret Key!");
                    }
                }} 
            />
        </header>
    );
};

export default Navbar;
