// src/Footer.js
import React from 'react';
import './styles.css'; // Import your styles if needed
import kickicon from './images/kick-icon1.png';
import discordicon from './images/discord-icon.png';
import youtubeicon from './images/youtube-icon.png';

const Footer = () => {
    return (
        <footer>
            <div className="footer-container">
                {/* Socials Section */}
                <div className="footer-section socials">
                    <h3>Follow Me</h3>
                    <ul className="social-icons">
                        <li>
                            <a href="https://kick.com/v3nli" target="_blank" rel="noopener noreferrer">
                                <img src={kickicon} alt="Kick" /> {/* Use kickicon variable correctly */}
                            </a>
                        </li>
                        <li>
                            <a href="https://discord.com/invite/5vEcPew9qF" target="_blank" rel="noopener noreferrer">
                                <img src={discordicon} alt="Discord" />
                            </a>
                        </li>
                        <li>
                            <a href="https://www.youtube.com/channel/UCHJDAO3V9tYOJLP1VduPPGg?sub_confirmation=1" target="_blank" rel="noopener noreferrer">
                                <img src={youtubeicon} alt="YouTube" />
                            </a>
                        </li>
                    </ul>
                </div>

                {/* Info Section */}
                <div className="footer-section info">
                    <h3>Information</h3>
                    <ul className="footer-links">
                        <li><a href="#">Terms of Service</a></li>
                        <li><a href="#">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>

            {/* All Rights Reserved Section */}
            <div className="footer-bottom">
                <p>&copy; 2024 <a href="https://v3nli.com">v3nli.com</a>. All rights reserved.</p>
            </div>

            {/* Credits Section */}
            <div className="footer-credits">
                <p>
                    Made by <a href="https://whyomar.com" target="_blank" rel="noopener noreferrer">@whyomar</a>
                </p>
            </div>
        </footer>
    );
};

export default Footer;
