import React, { useState } from 'react';
import './AdminModal.css';

const AdminModal = ({ isOpen, onClose, onLogin }) => {
    const [secretKey, setSecretKey] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (onLogin) {
            onLogin(secretKey); 
        } else {
            console.error('onLogin is not a function');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="modal">
            <h2>Admin Login</h2>
            <form onSubmit={handleSubmit}>
                <input
                    type="text"
                    value={secretKey}
                    onChange={(e) => setSecretKey(e.target.value)}
                    placeholder="Enter secret key"
                    required
                />
                <button type="submit">Submit</button>
                <button type="button" onClick={onClose}>Cancel</button>
            </form>
        </div>
    );
};

export default AdminModal;
