// src/Leaderboard.js
import React, { useEffect, useState } from 'react';
import './styles.css';
import Footer from './Footer';
import Navbar from './Navbar';
import firstplace from './images/1st.png';
import secondplace from './images/2nd.png';
import thirdplace from './images/3rd.png';
import overlayImage from './images/TEST.png';
import overlayImagesecond from './images/TEST2.png';
import crownImage from './images/crown.png';
import adminicon from './images/adminicon.png';
import AdminModal from './AdminModal';
import coinIcon from './images/chicken-coin.png';
import logo1 from './images/hypedrop.png'; // Replace with your actual logo image paths
import logo2 from './images/rustmagic.png';
import logo3 from './images/chicken.png';
import logo4 from './images/rbxgold.png';
import logo5 from './images/rain.png';


const Leaderboard = () => {
    const [timer, setTimer] = useState(0);
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [isSendButtonVisible, setSendButtonVisible] = useState(false); // Ensure this is initialized to false
const [isAdminModalOpen, setAdminModalOpen] = useState(false);


    // Define rewards
    const rewards = {
        1: 150,
        2: 125,
        3: 75,
        4: 50,
        5: 40,
        6: 30,
        7: 20,
        8: 10,
        9: 5,
        10: 5
    };

    const sendLeaderboardToDiscord = async (leaderboardData) => {
        const webhookURL = "https://discord.com/api/webhooks/1300856456068730941/-LkA00d3Hvh6b8_wBVtccvtBC_zNtF7UvkKvbokn1tV0aXrPluGjSnL-PBs815YPaqts"; // Replace with your actual webhook URL
        const top10 = leaderboardData.slice(0, 10);

        const message = {
            content: "📊 **Weekly Leaderboard**",
            embeds: [
                {
                    title: "Top 10 Users and Their Rewards",
                    color: 0x00ff00,
                    fields: top10.map((user, index) => ({
                        name: `${index + 1}. ${user.username}`,
                        value: `Wagered: $${user.wagerAmount} | Reward: $${user.reward}`,
                        inline: true,
                    })),
                    footer: { text: "Leaderboard reset weekly" },
                    timestamp: new Date().toISOString(),
                },
            ],
        };

        try {
            await fetch(webhookURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(message),
            });
            console.log("Leaderboard sent to Discord.");
        } catch (error) {
            console.error("Error sending leaderboard to Discord:", error);
        }
    };

    useEffect(() => {
        const now = Date.now();
        const weekDuration = 7 * 24 * 60 * 60 * 1000;

        const lastReset = localStorage.getItem('lastReset') ? parseInt(localStorage.getItem('lastReset')) : now;
        const startOfWeek = new Date(lastReset);
        startOfWeek.setHours(0, 0, 0, 0);
        startOfWeek.setDate(startOfWeek.getDate() - startOfWeek.getDay() + 1);

        const timeLeft = weekDuration - (now - startOfWeek.getTime());
        if (timeLeft <= 0) {
            localStorage.setItem('lastReset', now);
            setTimer(weekDuration);
            resetWeeklyLeaderboard();
        } else {
            setTimer(timeLeft);
        }

        const countdown = setInterval(() => {
            setTimer(prevTime => {
                if (prevTime <= 1000) {
                    resetWeeklyLeaderboard();
                    return weekDuration;
                }
                return prevTime - 1000;
            });
        }, 1000);

        return () => clearInterval(countdown);
    }, []);

    const fetchLeaderboardData = async () => {
        try {
            const response = await fetch('https://leaderboard.v3nli.com/api/leaderboard');
            
            if (!response.ok) {
                console.error('Failed to fetch leaderboard data:', response.statusText);
                return [];
            }
            
            const result = await response.json();
            console.log('Fetched leaderboard data:', result);

            const updatedData = result.referrals.map((user, index) => ({
                ...user,
                reward: rewards[index + 1] || 0,
            }));

            setLeaderboardData(updatedData);
            console.log('Updated leaderboard data with rewards:', updatedData);

            return updatedData;
        } catch (error) {
            console.error('Error fetching leaderboard data:', error);
        }
    };

    useEffect(() => {
        fetchLeaderboardData();
    }, []);

    const handleAdminLogin = (key) => {
        if (key === "v3your8j2ls9e") { // Replace with your actual secret key
            setSendButtonVisible(true); // Show the button if the key is correct
        } else {
            alert("Invalid Secret Key!");
            setSendButtonVisible(false); // This hides the button if the key is incorrect
        }
    };
    

    const resetWeeklyLeaderboard = async () => {
        const updatedData = await fetchLeaderboardData();
        sendLeaderboardToDiscord(updatedData);
        localStorage.setItem('lastReset', Date.now());
    };

    const formatTime = (milliseconds) => {
        const totalSeconds = Math.floor(milliseconds / 1000);
        const days = Math.floor(totalSeconds / (24 * 60 * 60));
        const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${days}d ${hours}h ${minutes}m ${seconds}s`;
    };

    return (
        <div>
            <div id="particles-js" className="leaderboard-page"></div>
            <main>
                <section className="leaderboard-section">
                    <div className="container">
                    <h1 style={{
    background: 'linear-gradient(to right, #FFD700, #FFFF)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontSize: '36px',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px'
}}>
    <img src={coinIcon} alt="Coin Icon" style={{ width: '36px', height: '36px' }} />
    500 WEEKLY CODE
    <a 
        href="https://chicken.gg/r/v3nli" 
        target="_blank" 
        rel="noopener noreferrer"
        style={{
            color: '#FFA500', 
            textShadow: '0 0 10px rgba(255, 165, 0, 0.8), 0 0 20px rgba(255, 165, 0, 0.6)',
            textDecoration: 'none'
        }}
    >
        V3NLI
    </a>
    LEADERBOARD!
</h1>


<div className="logo-container" style={{ display: 'flex', justifyContent: 'space-between', margin: '26px 0' }}>
    <div className="logo-item" style={{ flex: 0.8, position: 'relative', background: 'rgba(255, 255, 255, 0.06)', borderRadius: '5px', padding: '5px', marginRight: '0' }}>
        <img src={logo1} alt="Logo 1" style={{ width: '80%', height: 'auto', margin: '0 auto' }} />
        <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: '6px', background: 'linear-gradient(to right, #d3d3d3, transparent)' }}></div> {/* White-greyish line */}
    </div>

    <div className="logo-item" style={{ flex: 0.8, position: 'relative', background: 'rgba(255, 255, 255, 0.06)', borderRadius: '5px', padding: '5px', marginRight: '0' }}>
        <img src={logo2} alt="Logo 2" style={{ width: '80%', height: 'auto', margin: '0 auto' }} />
        <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: '6px', background: 'linear-gradient(to right, #ff007f, transparent)' }}></div> {/* Pink line */}
    </div>

    <div className="logo-item" style={{ flex: 0.8, position: 'relative', background: 'rgba(255, 255, 255, 0.06)', borderRadius: '5px', padding: '5px', marginRight: '0' }}>
        <img src={logo3} alt="Logo 3" style={{ width: '80%', height: 'auto', margin: '0 auto' }} />
        <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: '6px', background: 'linear-gradient(to right, #4169e1, transparent)' }}></div> {/* Light navy blue to royal blue to gold yellow line */}
        {/* Arrow pointing to logo3 */}
        <div style={{ position: 'absolute', bottom: '-10px', left: '50%', transform: 'translateX(-50%)', width: '0', height: '0', borderLeft: '10px solid transparent', borderRight: '10px solid transparent', borderTop: '10px solid #ffd700' }}></div>
    </div>

    <div className="logo-item" style={{ flex: 0.8, position: 'relative', background: 'rgba(255, 255, 255, 0.06)', borderRadius: '5px', padding: '5px', marginRight: '0' }}>
        <img src={logo4} alt="Logo 4" style={{ width: '80%', height: 'auto', margin: '0 auto' }} />
        <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: '6px', background: 'linear-gradient(to right, #9966cc, transparent)' }}></div> {/* Amethyst purple line */}
    </div>

    <div className="logo-item" style={{ flex: 0.8, position: 'relative', background: 'rgba(255, 255, 255, 0.06)', borderRadius: '5px', padding: '5px', marginRight: '0' }}>
        <img src={logo5} alt="Logo 5" style={{ width: '80%', height: 'auto', margin: '0 auto' }} />
        <div style={{ position: 'absolute', bottom: 0, left: 0, right: 0, height: '6px', background: 'linear-gradient(to right, transparent, #ffd700)' }}></div> {/* Gold line */}
    </div>
</div>

   {/* Add the Sign Up button here */}
   <a
    href="https://chicken.gg/r/v3nli"
    target="_blank"
    rel="noopener noreferrer"
    onMouseEnter={(e) => {
        e.currentTarget.style.transform = 'scale(1.05)'; // Scale up on hover
        e.currentTarget.style.background = 'orange'; // Change background color on hover
        e.currentTarget.style.color = '#fff'; // Change text color on hover
    }}
    onMouseLeave={(e) => {
        e.currentTarget.style.transform = 'scale(1)'; // Reset scale on mouse leave
        e.currentTarget.style.background = 'linear-gradient(to right, gold, orange)'; // Reset background color
        e.currentTarget.style.color = '#000'; // Reset text color
    }}
    style={{
        background: 'linear-gradient(to right, gold, orange)', // Gold gradient
        border: 'none',
        borderRadius: '5px',
        padding: '10px 20px',
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#000',
        margin: '20px auto',
        display: 'block',
        textDecoration: 'none', // Optional: remove underline from link
        transition: 'transform 0.3s ease, background 0.3s ease, color 0.3s ease', // Smooth transition for all effects
    }}
>
    Sign Up
</a>


                        <div className="podium-container">
                            <div className="podium-spot silver">
                                {leaderboardData[1] && (
                                    <>
                                        <img src={secondplace} className="reward-image" alt="2nd Place" />
                                        <img src={leaderboardData[1].imageurl} className="podium-profile-pic" alt="Profile" />
                                        <div className="podium-rank">2nd</div>
                                        <div className="podium-username">{leaderboardData[1].username}</div>
                                        <div className="podium-wagered">Wagered: ${leaderboardData[1].wagerAmount}</div>
                                    </>
                                )}
                            </div>

                            <div className="admin-icon" onClick={() => setAdminModalOpen(true)}>
                        </div>

                        {isSendButtonVisible && (
    <button onClick={() => sendLeaderboardToDiscord(leaderboardData)} className="send-webhook-button">
        Send Leaderboard to Discord
    </button>
)}
                            <div className="overlay">
                                <img src={overlayImage} className="floating-image subtle-floating" alt="Overlay" />
                            </div>
                            <div className="overlayd">
                                <img src={overlayImagesecond} className="floating-image subtle-floating" alt="Overlay" />
                            </div>

                            <div className="podium-spot gold">
                                {leaderboardData[0] && (
                                    <>
                                        <img src={firstplace} className="reward-image" alt="1st Place" />
                                        <div className="crown-container">
                                            <img src={crownImage} className="crown-animation" alt="Crown" />
                                        </div>
                                        <img src={leaderboardData[0].imageurl} className="podium-profile-pic" alt="Profile" />
                                        <div className="podium-rank">1st</div>
                                        <div className="podium-username">{leaderboardData[0].username}</div>
                                        <div className="podium-wagered">Wagered: ${leaderboardData[0].wagerAmount}</div>
                                    </>
                                )}
                            </div>

                            <div className="podium-spot bronze">
                                {leaderboardData[2] && (
                                    <>
                                        <img src={thirdplace} className="reward-image" alt="3rd Place" />
                                        <img src={leaderboardData[2].imageurl} className="podium-profile-pic" alt="Profile" />
                                        <div className="podium-rank">3rd</div>
                                        <div className="podium-username">{leaderboardData[2].username}</div>
                                        <div className="podium-wagered">Wagered: ${leaderboardData[2].wagerAmount}</div>
                                    </>
                                )}
                            </div>
                        </div>

                      

                        <div id="countdown-timer" className="countdown-timer">
                            Time until next reset: <span id="timer">{formatTime(timer)}</span>
                        </div>

                        <div className="leaderboard-table">
    <div className="leaderboard-header" style={{ background: 'linear-gradient(to right, gold, orange)' }}>
        <div style={{ color: 'black' }} className="rank">Rank</div>
        <div style={{ color: 'black' }} className="username">Username</div>
        <div style={{ color: 'black' }} className="wagered">Wagered Amount</div>
        <div style={{ color: 'black' }} className="reward">Reward</div>
    </div>
    {leaderboardData.slice(3, 10).map((user, index) => (
        <div
            className="leaderboard-row"
            key={user.username}
            style={{ backgroundColor: index % 2 === 0 ? 'black' : 'transparent' }}
        >
            <div className="rank">{index + 4}</div>
            <div className="username" style={{ color: '#FFFF', fontWeight: 'bold' }}>
                <img src={user.imageurl} className="table-profile-pic" alt="Profile" />
                {user.username}
            </div>
            <div className="wagereds">
    <span>${user.wagerAmount}</span>
</div>
            <div className="reward" style={{ color: '#00FF00' }}>${user.reward}</div>
        </div>
    ))}
</div>

                    </div>
                </section>
            </main>
            <Footer />
            <AdminModal 
  isOpen={isAdminModalOpen} 
  onClose={() => setAdminModalOpen(false)} 
  onLogin={handleAdminLogin} // Corrected from onSubmit to onLogin
/>

            </div>
    );
};

export default Leaderboard;
